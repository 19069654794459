<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('deliveryAdd')"
          @click="$router.push('delivery/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('deliveryDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه تسليم العملاء
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />
      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />
      <Column field="date" header="تاريخ الارسال من الفنين" :sortable="true" />
      <Column field="contractsId.total" header="قيمه العقد" :sortable="true" />
      <Column
        field="id"
        header="ارجاع الى الفنين"
        v-if="$checkRoles('deliveryToSecretary')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            v-tooltip="'ارجاع الى الفنين'"
            type="button"
            @click="
              backDelivery(slotProps.data.id, slotProps.data.contractsId.id)
            "
            icon="pi pi-question"
            class="p-button-warning p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column
        field="id"
        header="التسليم"
        v-if="$checkRoles('deliveryToSecretary')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            v-tooltip="'التسليم'"
            type="button"
            @click="toAddDelivery(slotProps.data)"
            icon="pi pi-reply"
            v-if="!slotProps.data.toDelivery"
            class="p-button-info p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            class="p-datatable-customers table table-striped"
            :value="[slotProps.data]"
          >
            <template #header>
              قائمه الملاحظات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="contractsId.note" header="ملاحظات العقد" />

            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
            <!-- <Column field="installationsId.note" header="ملاحظات التركيب" /> -->
            <Column field="note" header="ملاحظات التسليم" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="الارجاع الى التركيبات"
      :visible.sync="displayBasicToDelivery"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="backDeliveryNote" class="form-label">
              ملاحظات
            </label>
            <textarea
              class="form-control"
              id="backDeliveryNote"
              v-model="backDeliveryNote"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="saveBackDelivery"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToDelivery = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      deliveryStepsList: [],
      backDeliveryId: null,
      backDeliveryServesId: null,
      backDeliveryNote: null,
      displayBasicToDelivery: false,
      contractsId: null,
    };
  },
  methods: {
    getData() {
      this.$http.get(`delivery/getAllData`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    backDelivery(id, contractsId) {
      this.backDeliveryId = id;
      // this.backDeliveryServesId = id2;
      this.backDeliveryNote = null;
      this.displayBasicToDelivery = true;
      this.contractsId = contractsId;
    },
    saveBackDelivery() {
      if (this.backDeliveryNote) {
        this.displayBasicToDelivery = false;
        this.$http
          .put(`delivery/${this.backDeliveryId}`, {
            backDelivery: true,
            backDeliveryNote: this.backDeliveryNote,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التحويل بنجاح',
                life: 3000,
              });

              this.$http
                .put(`contracts/addInstalltions/${this.contractsId}`, {
                  status: 'فى التشغيل',
                })
                .then(() => {
                  this.getData();
                });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },

    toAddDelivery(data) {
      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      this.$http
        .put(`delivery/toDone/${data.id}`, {
          toDone: true,
        })
        .then(
          () => {
            this.$http
              .put(`contracts/addInstalltions/${data.contractsId.id}`, {
                status: 'تم التسليم',
                doneDate: new Date(),
              })
              .then(() => {});

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التحويل بنجاح',
              life: 3000,
            });

            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (!this.$checkRoles('deliveryVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
